export default {
  PAIS: 'El país de procedencia es necesario.',
  DOCUMENT_TYPE_REQUIRED: 'Tipo de documento es necesario.',
  DOCUMENT_REQUIRED: 'El identificador es necesario.',
  DOCUMENT_INVALID: 'El identificador no es válido.',
  NIE_INVALID: 'El NIE no es válido.',
  NAME_REQUIRED: 'Nombre es necesario.',
  LASTNAME_REQUIRED: 'Primer apellido es necesario.',
  SECOND_LASTNAME_REQUIRED: 'Segundo apellido es necesario.',
  PHONE_REQUIRED: 'Teléfono es necesario.',
  EMAIL_REQUIRED: 'Email es necesario.',
  EMAIL_INVALID: 'El email no es válido.',
  EMAIL_NOT_EQUAL: 'Los emails no coinciden.',
  POSTALCODE_REQUIRED: 'Código postal es necesario.',
  PROVINCE_REQUIRED: 'Provincia es necesaria.',
  LOCALITY_REQUIRED: 'Localidad es necesaria.',
  VIA_TYPE_REQUIRED: 'Tipo vía es necesaria.',
  ADDRESS_REQUIRED: 'Nombre de la dirección es necesaria.',
  ADDRESS_NUMBER_REQUIRED: 'Número de dirección es necesario.',
  IBAN_REQUIRED: 'IBAN es necesario.',
  IBAN_INVALID: 'El IBAN introducido no es válido.',
  BICSWIFT_REQUIRED: 'BIC/SWIFT es necesario.',
  DOCUMENT_PIC_FRONT_REQUIRED: 'Fotografía DNI anverso es necesaria.',
  DOCUMENT_VIRTUAL_BATTERY_REQUIRED: 'Documento de Batería virtual necesario.',
  DOCUMENT_GESTION_BATERIA: 'El modo de activación es necesario.',
  CODIGO_BATERIA_VIRTUAL: 'El código de batería virtual es necesario.',
  IDENTIFICADOR_BATERIA_VIRTUAL: 'El identificador de batería virtual es necesario.',
  DOCUMENT_PIC_BACK_REQUIRED: 'Fotografía DNI reverso es necesaria.',
  COMMERCIAL_COMMS_REQUIRED: 'Es necesario aceptar recibir comunicaciones comerciales.',
  TEMS_PRIVACY_REQUIRED:
    'Es necesario aceptar los términos y condiciones de contratación y política de privacidad.',
  MARKETER_START_SERVICE_REQUIRED: marketer =>
    `Es necesario aceptar que ${marketer} inicie el servicio antes del fin del derecho de desistimiento.`,
  // Datos de contacto
  CONTACT_DOCUMENT_TYPE_REQUIRED: 'Tipo de documento necesario.',
  CONTACT_DOCUMENT_REQUIRED: 'DNI/CIF/NIE necesario.',
  CONTACT_DOCUMENT_DNI_INVALID: 'El DNI es invalido',
  CONTACT_DOCUMENT_NIE_INVALID: 'El NIE es invalido',
  CONTACT_NAME_REQUIRED: 'El nombre es necesario.',
  CONTACT_LASTNAME_REQUIRED: 'El primer apellido es necesario.',
  CONTACT_SECOND_LASTNAME_REQUIRED: 'El segundo apellido es necesario.',
  CONTACT_PHONE_REQUIRED: 'El teléfono es necesario.',
  CONTACT_EMAIL_REQUIRED: 'El correo es necesario.',
  CONTACT_EMAIL_INVALID: 'El correo no es válido.',
  CONTACT_EMAIL_NOT_EQUAL: 'Los correos no coinciden.',

  // Titular facturación
  BILLING_OWNER_DOCUMENT_TYPE_REQUIRED: 'Tipo de documento del titular es necesario.',
  BILLING_OWNER_DOCUMENT_REQUIRED: 'DNI/CIF/NIE del titular es necesario.',
  BILLING_OWNER_DOCUMENT_INVALID: 'El DNI del titular no es válido.',
  BILLING_OWNER_NIE_INVALID: 'El NIE del titular no es válido.',
  BILLING_OWNER_NAME_REQUIRED: 'Nombre del titular es necesario.',
  BILLING_OWNER_LASTNAME_REQUIRED: 'Primer apellido del titular es necesario.',
  BILLING_OWNER_SECOND_LASTNAME_REQUIRED: 'Segundo apellido del titular es necesario.',
  // Direccion de titular de contacto
  OWNER_ADDRESS_POSTALCODE_REQUIRED: 'Código postal del titular es necesario.',
  OWNER_ADDRESS_PROVINCE_REQUIRED: 'Provincia del titular es necesaria.',
  OWNER_ADDRESS_LOCALITY_REQUIRED: 'Localidad del titular es necesaria.',
  OWNER_ADDRESS_VIA_TYPE_REQUIRED: 'Tipo vía del titular es necesaria.',
  OWNER_ADDRESS_ADDRESS_REQUIRED: 'Nombre de la dirección del titular es necesaria.',
  OWNER_ADDRESS_ADDRESS_NUMBER_REQUIRED: 'Número de dirección del titular es necesario.',
  // Datos titular (empresa)
  BUSSINESS_DOCUMENT_TYPE_INVALID: 'El tipo de documento no puede estar vacío.',
  BUSSINESS_IDENTIFICATOR_INVALID: 'El identificador no puede estar vacío.',
  BUSSINESS_PERSONA_TYPE: 'El tipo de persona no puede estar vacío.',
  BUSSINESS_SOCIAL_REASON: 'La razón social no puede estar vacia.',
  BUSSINESS_PHONE: 'El teléfono esta vacío o no es válido.',
  BUSSINESS_EMAIL: 'El e-mail esta vacío o no es válido.',
  BUSSINESS_EMAIL_CONFIRMATION: 'Los correos no coniciden.',
  BUSSINESS_PIC_NOTARIAL: 'Es necesaria la foto de la escritura poder notarial.',
  BUSSINESS_PIC_CIF: 'Es necesaria la foto de CIF empresa.',
  BUSSINESS_SIGNATURE_NAME: 'Es necesario el nombre del firmante.',
  BUSSINESS_SIGNATURE_LASTNAME: 'Es necesario el primer apellido del firmante.',
  BUSSINESS_SIGNATURE_SECONDLASTNAME: 'Es necesario el segundo apellido del firmante.',
  BUSSINESS_SIGNATURE_DNI: 'Es necesario el DNI del firmante.',
  BUSSINESS_SIGNATURE_PHONE: 'Es necesario el teléfono del firmante.',
  BUSSINESS_SIGNATURE_EMAIL: 'Es necesario el e-mail del firmante.',
  BUSSINESS_SIGNATURE_EMAIL_CONFIRMATION: 'Los correos de los firmantes no coinciden.',
  BUSSINESS_CNAE: 'El CNAE es necesario.',
  BILL_REQUIRED: 'Recibo a nombre del titular es necesario.',
  LASTINVOICE_REQUIRED: 'Factura anterior es necesaria.'
};
